<template>
  <div class="container" style="width:100%; margin-top:5%;">
    <div class="title" >
      Calls Report
      <button class="export-button" @click="exportTable">Export</button>
    </div>
    <div class="search-box">
      <input type="text" v-model="searchQuery" placeholder="Search by Phone, Company Name, State, Tags" @keyup="searchTable" />
    </div>
    <div class="filter-box">
      <label for="typeFilter">Filter by Type:</label>
      <select id="typeFilter" v-model="typeFilter" @change="filterTable">
        <option value="All">All</option>
        <option value="Outbound All">Outbound All</option>
        <option value="Outbound Voicemails">Outbound Voicemails</option>
        <option value="Outbound Human">Outbound Human</option>
        <option value="Outbound Silence">Outbound Silence</option>
        <option value="Outbound Fax">Outbound Fax</option>
        <option value="Outbound Other">Outbound Other</option>
        <option value="Web">Web</option>
        <option value="Inbound All">Inbound All</option>
        <option value="Inbound Voicemail Call Backs">Inbound Voicemail Call Backs</option>
        <option value="Inbound Caller ID Call Backs">Inbound Caller ID Call Backs</option>
        <option value="Inbound All - Exclude Caller ID Call Backs">Inbound All - Exclude Caller ID Call Backs</option>
      </select>
      <label for="talkTimeFilter">Filter by Talk Time:</label>
      <select id="talkTimeFilter" v-model="talkTimeFilter" @change="filterTable">
        <option value="All">All</option>
        <option value="2">Over 2 Minutes</option>
        <option value="5">Over 5 Minutes</option>
        <option value="10">Over 10 Minutes</option>
      </select>
    </div>
    <div class="date-filter-box">
      <label for="dateFilter">Filter by Date:</label>
      <select id="dateFilter" v-model="dateFilter" @change="filterByDate">
        <option value="All">All</option>
        <option value="Today">Today</option>
        <option value="Yesterday">Yesterday</option>
        <option value="This Week">This Week</option>
        <option value="Last Week">Last Week</option>
        <option value="This Month">This Month</option>
        <option value="Last Month">Last Month</option>
        <option value="Last 7 Days">Last 7 Days</option>
        <option value="Last 30 Days">Last 30 Days</option>
        <option value="Last 90 Days">Last 90 Days</option>
        <option value="YTD">YTD</option>
        <option value="Custom">Custom</option>
      </select>
      <div class="date-range-picker" v-if="dateFilter === 'Custom'">
        <DatePicker v-model="customStartDate" placeholder="Start Date" />
        <DatePicker v-model="customEndDate" placeholder="End Date" />
        <button @click="applyCustomDateRange">Apply</button>
      </div>
    </div>
    <div class="date-filter-box">
      <label for="toFilter">Filter by TOs:</label>
      <select id="toFilter" v-model="toFilter" @change="fetchData" class="form-control form-control-sm">
        <option value="All">All</option>
        <option value="Missed">Missed TOs</option>
        <option value="Answered">Answered TOs</option>
        <option value="Completed">Completed TOs</option>
      </select>
    </div>
    <div class="page-controls" >
      <label for="rowsPerPage">Rows per page:</label>
      <select id="rowsPerPage" v-model="rowsPerPage" @change="updateRowsPerPage">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div style="overflow-x: scroll;">
      <table id="callTable">
      <thead>
        <tr>
          <th style="width: 40px;">#</th>
          <th @click="sortTable('date')">Date</th>
          <th @click="sortTable('time')">Time</th>
          <th @click="sortTable('type')">Type</th>
          <th @click="sortTable('phoneNumber')">Phone Number</th>
          <th @click="sortTable('state')">State</th>
          <th @click="sortTable('companyName')">Company Name</th>
          <th @click="sortTable('companyName')">Human Detected</th>
          <th @click="sortTable('companyName')">Queue Detected</th>
          <th @click="sortTable('companyName')">Rep Detected</th>
          <th @click="sortTable('companyName')">Prospect Hungup</th>
          <th @click="sortTable('duration')">Talk Time</th>
          <th>Play</th>
          <th>Download</th>
          <th>Tag</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(call, index) in filteredCalls" :key="call.id">
          <td>{{ index + 1 }}</td>
          <td>{{ new Date(call.start_time).toLocaleDateString() }}</td>
          <td>{{ new Date(call.start_time).toLocaleTimeString() }}</td>
          <td>{{ getCallType(call) }}</td>
          <td><a :href="`tel:${getPhoneNumber(call)}`">{{ getPhoneNumber(call) }}</a></td>
          <td>{{ getStateFromPhoneNumber(getPhoneNumber(call)) }}</td>
          <td>{{ getCompanyName(call) }}</td>
          <td>{{ call.human_detected }} Sec</td>
          <td>{{ call.queue_detected }} Sec</td>
          <td>{{ call.rep_detected }} Sec</td>
          <td>{{ call.prospect_hungup }} Sec</td>
          <td>{{ call.duration }} Sec</td>
          <td>
            <button class="play-button" @click="toggleAudio(call)">
              {{ playingCallId === call.id ? 'Pause' : 'Play' }}
            </button>
          </td>
          <td><button class="download-button" @click="downloadAudio(call)" v-if="call.recordingUrl">Download</button></td>
          <td>
            <ul class="tag-list">
              <li v-for="tag in call.tags" :key="tag">{{ tag }} <span class="remove-tag" @click="removeTag(call, tag)">x</span></li>
            </ul>
            <input class="tag-input" type="text" placeholder="New Tag" @keypress="addTag($event, call)" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <a href="#" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">&laquo;</a>
      <a v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }" @click="changePage(page)">{{ page }}</a>
      <a href="#" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">&raquo;</a>
    </div>
    </div>

    <div class="totals" id="totals">Showing {{ filteredCalls.length }} of {{ totalCalls }} calls</div>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      searchQuery: '',
      typeFilter: 'All',
      talkTimeFilter: 'All',
      dateFilter: 'All',
      customStartDate: '',
      customEndDate: '',
      rowsPerPage: 50,
      currentPage: 1,
      calls: [],
      totalCalls: 0,
      sortDirection: 'asc',
      areaCodeToState: {
        '201': 'New Jersey',
        '202': 'District of Columbia',
        '203': 'Connecticut',
        '205': 'Alabama',
        '206': 'Washington',
        '207': 'Maine',
        '208': 'Idaho',
        '209': 'California',
        '210': 'Texas',
        '212': 'New York',
        '213': 'California',
        '214': 'Texas',
        '215': 'Pennsylvania',
        '216': 'Ohio',
        '217': 'Illinois',
        '218': 'Minnesota',
        '219': 'Indiana',
        '220': 'Ohio',
        '224': 'Illinois',
        '225': 'Louisiana',
        '228': 'Mississippi',
        '229': 'Georgia',
        '231': 'Michigan',
        '234': 'Ohio',
        '239': 'Florida',
        '240': 'Maryland',
        '248': 'Michigan',
        '251': 'Alabama',
        '252': 'North Carolina',
        '253': 'Washington',
        '254': 'Texas',
        '256': 'Alabama',
        '260': 'Indiana',
        '262': 'Wisconsin',
        '267': 'Pennsylvania',
        '269': 'Michigan',
        '270': 'Kentucky',
        '272': 'Pennsylvania',
        '274': 'Wisconsin',
        '276': 'Virginia',
        '281': 'Texas',
        '301': 'Maryland',
        '302': 'Delaware',
        '303': 'Colorado',
        '304': 'West Virginia',
        '305': 'Florida',
        '307': 'Wyoming',
        '308': 'Nebraska',
        '309': 'Illinois',
        '310': 'California',
        '312': 'Illinois',
        '313': 'Michigan',
        '314': 'Missouri',
        '315': 'New York',
        '316': 'Kansas',
        '317': 'Indiana',
        '318': 'Louisiana',
        '319': 'Iowa',
        '320': 'Minnesota',
        '321': 'Florida',
        '323': 'California',
        '325': 'Texas',
        '330': 'Ohio',
        '331': 'Illinois',
        '332': 'New York',
        '334': 'Alabama',
        '336': 'North Carolina',
        '337': 'Louisiana',
        '339': 'Massachusetts',
        '346': 'Texas',
        '347': 'New York',
        '351': 'Massachusetts',
        '352': 'Florida',
        '360': 'Washington',
        '361': 'Texas',
        '364': 'Kentucky',
        '380': 'Ohio',
        '385': 'Utah',
        '386': 'Florida',
        '401': 'Rhode Island',
        '402': 'Nebraska',
        '404': 'Georgia',
        '405': 'Oklahoma',
        '406': 'Montana',
        '407': 'Florida',
        '408': 'California',
        '409': 'Texas',
        '410': 'Maryland',
        '412': 'Pennsylvania',
        '413': 'Massachusetts',
        '414': 'Wisconsin',
        '415': 'California',
        '417': 'Missouri',
        '419': 'Ohio',
        '423': 'Tennessee',
        '424': 'California',
        '425': 'Washington',
        '430': 'Texas',
        '432': 'Texas',
        '434': 'Virginia',
        '435': 'Utah',
        '440': 'Ohio',
        '442': 'California',
        '443': 'Maryland',
        '458': 'Oregon',
        '463': 'Indiana',
        '464': 'Illinois',
        '469': 'Texas',
        '470': 'Georgia',
        '475': 'Connecticut',
        '478': 'Georgia',
        '479': 'Arkansas',
        '480': 'Arizona',
        '484': 'Pennsylvania',
        '501': 'Arkansas',
        '502': 'Kentucky',
        '503': 'Oregon',
        '504': 'Louisiana',
        '505': 'New Mexico',
        '507': 'Minnesota',
        '508': 'Massachusetts',
        '509': 'Washington',
        '510': 'California',
        '512': 'Texas',
        '513': 'Ohio',
        '515': 'Iowa',
        '516': 'New York',
        '517': 'Michigan',
        '518': 'New York',
        '520': 'Arizona',
        '530': 'California',
        '531': 'Nebraska',
        '534': 'Wisconsin',
        '539': 'Oklahoma',
        '540': 'Virginia',
        '541': 'Oregon',
        '551': 'New Jersey',
        '559': 'California',
        '561': 'Florida',
        '562': 'California',
        '563': 'Iowa',
        '567': 'Ohio',
        '570': 'Pennsylvania',
        '571': 'Virginia',
        '573': 'Missouri',
        '574': 'Indiana',
        '575': 'New Mexico',
        '580': 'Oklahoma',
        '585': 'New York',
        '586': 'Michigan',
        '601': 'Mississippi',
        '602': 'Arizona',
        '603': 'New Hampshire',
        '605': 'South Dakota',
        '606': 'Kentucky',
        '607': 'New York',
        '608': 'Wisconsin',
        '609': 'New Jersey',
        '610': 'Pennsylvania',
        '612': 'Minnesota',
        '614': 'Ohio',
        '615': 'Tennessee',
        '616': 'Michigan',
        '617': 'Massachusetts',
        '618': 'Illinois',
        '619': 'California',
        '620': 'Kansas',
        '623': 'Arizona',
        '626': 'California',
        '628': 'California',
        '629': 'Tennessee',
        '630': 'Illinois',
        '631': 'New York',
        '636': 'Missouri',
        '641': 'Iowa',
        '646': 'New York',
        '650': 'California',
        '651': 'Minnesota',
        '657': 'California',
        '660': 'Missouri',
        '661': 'California',
        '662': 'Mississippi',
        '669': 'California',
        '678': 'Georgia',
        '680': 'New York',
        '681': 'West Virginia',
        '682': 'Texas',
        '689': 'Florida',
        '701': 'North Dakota',
        '702': 'Nevada',
        '703': 'Virginia',
        '704': 'North Carolina',
        '706': 'Georgia',
        '707': 'California',
        '708': 'Illinois',
        '712': 'Iowa',
        '713': 'Texas',
        '714': 'California',
        '715': 'Wisconsin',
        '716': 'New York',
        '717': 'Pennsylvania',
        '718': 'New York',
        '719': 'Colorado',
        '720': 'Colorado',
        '724': 'Pennsylvania',
        '725': 'Nevada',
        '727': 'Florida',
        '731': 'Tennessee',
        '732': 'New Jersey',
        '734': 'Michigan',
        '737': 'Texas',
        '740': 'Ohio',
        '743': 'North Carolina',
        '747': 'California',
        '754': 'Florida',
        '757': 'Virginia',
        '760': 'California',
        '762': 'Georgia',
        '763': 'Minnesota',
        '765': 'Indiana',
        '769': 'Mississippi',
        '770': 'Georgia',
        '772': 'Florida',
        '773': 'Illinois',
        '774': 'Massachusetts',
        '775': 'Nevada',
        '779': 'Illinois',
        '781': 'Massachusetts',
        '785': 'Kansas',
        '786': 'Florida',
        '801': 'Utah',
        '802': 'Vermont',
        '803': 'South Carolina',
        '804': 'Virginia',
        '805': 'California',
        '806': 'Texas',
        '808': 'Hawaii',
        '810': 'Michigan',
        '812': 'Indiana',
        '813': 'Florida',
        '814': 'Pennsylvania',
        '815': 'Illinois',
        '816': 'Missouri',
        '817': 'Texas',
        '818': 'California',
        '828': 'North Carolina',
        '830': 'Texas',
        '831': 'California',
        '832': 'Texas',
        '838': 'New York',
        '843': 'South Carolina',
        '845': 'New York',
        '847': 'Illinois',
        '848': 'New Jersey',
        '850': 'Florida',
        '854': 'South Carolina',
        '856': 'New Jersey',
        '857': 'Massachusetts',
        '858': 'California',
        '859': 'Kentucky',
        '860': 'Connecticut',
        '862': 'New Jersey',
        '863': 'Florida',
        '864': 'South Carolina',
        '865': 'Tennessee',
        '870': 'Arkansas',
        '872': 'Illinois',
        '878': 'Pennsylvania',
        '901': 'Tennessee',
        '903': 'Texas',
        '904': 'Florida',
        '906': 'Michigan',
        '907': 'Alaska',
        '908': 'New Jersey',
        '909': 'California',
        '910': 'North Carolina',
        '912': 'Georgia',
        '913': 'Kansas',
        '914': 'New York',
        '915': 'Texas',
        '916': 'California',
        '917': 'New York',
        '918': 'Oklahoma',
        '919': 'North Carolina',
        '920': 'Wisconsin',
        '925': 'California',
        '927': 'Florida',
        '928': 'Arizona',
        '929': 'New York',
        '931': 'Tennessee',
        '936': 'Texas',
        '937': 'Ohio',
        '940': 'Texas',
        '941': 'Florida',
        '945': 'Texas',
        '947': 'Michigan',
        '949': 'California',
        '951': 'California',
        '952': 'Minnesota',
        '954': 'Florida',
        '956': 'Texas',
        '957': 'New Mexico',
        '959': 'Connecticut',
        '970': 'Colorado',
        '971': 'Oregon',
        '972': 'Texas',
        '973': 'New Jersey',
        '978': 'Massachusetts',
        '979': 'Texas',
        '980': 'North Carolina',
        '984': 'North Carolina',
        '985': 'Louisiana',
        '989': 'Michigan'
      },
      playingCallId: null,
      audio: null,
      toFilter: 'All',
      salesRepId: ''
    };
  },
  computed: {
    visiblePages() {
      const pages = [];
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(startPage + 4, this.totalPages);

      if (endPage - startPage < 4) {
        startPage = Math.max(endPage - 4, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    filteredCalls() {
      console.log("pre-filter: ", this.calls);
      let filtered = this.calls.filter(call => {
        // Filter by search query
        if (this.searchQuery && !this.matchSearchQuery(call)) {
          return false;
        }
        // Filter by type
        if (this.typeFilter !== 'All' && this.getCallType(call) !== this.typeFilter) {
          return false;
        }
        // Filter by talk time
        if (this.talkTimeFilter !== 'All' && parseInt(call.duration) <= parseInt(this.talkTimeFilter) * 60) {
          return false;
        }

        if(this.toFilter !== 'All' && !this.matchToFilter(call)) {
          return false;
        }

        if(this.salesRepId && call.sales_rep_id !== this.salesRepId) {
          return false;
        }

        // Filter by date
        if (!this.matchDateFilter(call.start_time)) {
          return false;
        }
        return true;
      });
      let sliced = filtered.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage);
      console.log("filtered: ", filtered);
      console.log("sliced: ", sliced);
      return filtered;
    },
    totalPages() {
      return Math.ceil(this.totalCalls / this.rowsPerPage);
    },
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.filterTable();
      }
    },
    fetchData() {
      axios
        .get('https://123avatars.com/v2/calls', {
          params: {
            search: this.searchQuery,
            type: this.typeFilter,
            talkTime: this.talkTimeFilter,
            date: this.dateFilter,
            startDate: this.customStartDate,
            endDate: this.customEndDate,
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currentPage,
            toFilter: this.toFilter,
            salesRepId: this.salesRepId
          }
        })
        .then(response => {
          this.calls = response.data.calls; // Assign a new array
          this.totalCalls = response.data.total;
        })
        .catch(error => {
          console.error("There was an error fetching the calls data!", error);
        });
    },
    matchSearchQuery(call) {
      const query = this.searchQuery.toLowerCase();
      return (
        call.to_num.toLowerCase().includes(query) ||
        this.getCompanyName(call).toLowerCase().includes(query) ||
        this.getStateFromPhoneNumber(call.to_num).toLowerCase().includes(query) ||
        (call.tags || []).some(tag => tag.toLowerCase().includes(query))
      );
    },
    matchDateFilter(callDate) {
      const date = new Date(callDate);
      const today = new Date();
      switch (this.dateFilter) {
        case 'All':
          return true;
        case 'Today':
          return this.isSameDay(today, date);
        case 'Yesterday':
          const yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          return this.isSameDay(yesterday, date);
        case 'This Week':
          return this.isSameWeek(today, date);
        case 'Last Week':
          const lastWeek = new Date();
          lastWeek.setDate(today.getDate() - 7);
          return this.isSameWeek(lastWeek, date);
        case 'This Month':
          return this.isSameMonth(today, date);
        case 'Last Month':
          const lastMonth = new Date();
          lastMonth.setMonth(today.getMonth() - 1);
          return this.isSameMonth(lastMonth, date);
        case 'Last 7 Days':
          const last7Days = new Date();
          last7Days.setDate(today.getDate() - 7);
          return date >= last7Days && date <= today;
        case 'Last 30 Days':
          const last30Days = new Date();
          last30Days.setDate(today.getDate() - 30);
          return date >= last30Days && date <= today;
        case 'Last 90 Days':
          const last90Days = new Date();
          last90Days.setDate(today.getDate() - 90);
          return date >= last90Days && date <= today;
        case 'YTD':
          const startOfYear = new Date(today.getFullYear(), 0, 1);
          return date >= startOfYear && date <= today;
        case 'Custom':
          const startDate = new Date(this.customStartDate);
          const endDate = new Date(this.customEndDate);
          return date >= startDate && date <= endDate;
        default:
          return true;
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
    isSameWeek(date1, date2) {
      const startOfWeek1 = new Date(date1);
      startOfWeek1.setDate(date1.getDate() - date1.getDay());
      startOfWeek1.setHours(0, 0, 0, 0);

      const startOfWeek2 = new Date(date2);
      startOfWeek2.setDate(date2.getDate() - date2.getDay());
      startOfWeek2.setHours(0, 0, 0, 0);

      return startOfWeek1.getTime() === startOfWeek2.getTime();
    },
    isSameMonth(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
    },
    addTag(event, call) {
      if (event.key === 'Enter' && event.target.value.trim() !== '') {
        if (!call.tags) {
          call.tags = [];
        }
        call.tags.push(event.target.value.trim());
        this.calls = [...this.calls]; // Force reactivity update
        event.target.value = '';
        this.updateCallTags(call);
      }
    },
    removeTag(call, tag) {
      const index = call.tags.indexOf(tag);
      if (index > -1) {
        call.tags.splice(index, 1);
        this.calls = [...this.calls]; // Force reactivity update
        this.updateCallTags(call);
      }
    },
    updateCallTags(call) {
      axios.put(`https://123avatars.com/v2/calls/${call.id}/tags`, { tags: call.tags })
        .then(response => {
          console.log('Tags updated successfully');
        })
        .catch(error => {
          console.error('Error updating tags:', error);
        });
    },
    searchTable() {
      this.filterTable();
    },
    filterByDate() {
      this.currentPage = 1;
      this.filterTable();
    },
    applyCustomDateRange() {
      this.filterTable();
    },
    filterTable() {
      this.fetchData();
    },
    updateRowsPerPage() {
      this.currentPage = 1;
      this.filterTable();
    },
    changePage(page) {
      this.currentPage = page;
      this.filterTable();
    },
    sortTable(property) {
      const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.calls.sort((a, b) => {
        let x = a[property];
        let y = b[property];
        if (property === 'date') {
          x = new Date(a.start_time);
          y = new Date(b.start_time);
        }
        if (direction === 'asc') {
          return x > y ? 1 : x < y ? -1 : 0;
        } else {
          return x < y ? 1 : x > y ? -1 : 0;
        }
      });
      this.sortDirection = direction;
      this.calls = [...this.calls]; // Force reactivity update
    },
    exportTable() {
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        this.calls
          .map(call => [
            new Date(call.start_time).toLocaleDateString(),
            new Date(call.start_time).toLocaleTimeString(),
            this.getCallType(call),
            this.getPhoneNumber(call),
            this.getStateFromPhoneNumber(this.getPhoneNumber(call)),
            this.getCompanyName(call),
            call.duration,
            (call.tags || []).join(', '),
          ])
          .join('\n');

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'calls_report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCallType(call) {
      if (call.inbound) {
        if (call.is_voicemail_callback) {
          return "Inbound Voicemail Call Backs";
        } else if (call.is_caller_id_callback) {
          return "Inbound Caller ID Call Backs";
        } else if (call.is_web_call) {
          return "Web";
        } else {
          return "Inbound All";
        }
      } else {
        if(this.typeFilter == 'Outbound All') {
          return "Outbound All";
        }
        if(call.voicemail == 0 && call.human_answer == 0 && call.silence_answer == 0 && call.fax_answer == 0) {
          return "Outbound Other";
        }
        if(call.voicemail) {
          return "Outbound Voicemails";
        } else {
          return "Outbound Human";
        }
      }
    },
    matchToFilter(call) {
      switch(this.toFilter) {
        case 'Missed':
          return call.requested_to == 1 && call.answered_to == 0;
        break;
        case 'Answered':
          return call.answered_to == 1;
        break;
        case 'Completed':
          return call.qualified_to == 1;
          break;
        default:
          return false;
      }
    },
    getPhoneNumber(call) {
      return call.inbound ? call.from_num : call.to_num;
    },
    getStateFromPhoneNumber(phoneNumber) {
      const areaCode = phoneNumber.substring(2, 5); // Assuming phoneNumber is in the format +1XXXXXXXXXX
      return this.areaCodeToState[areaCode] || 'Unknown State';
    },
    getCompanyName(call) {
      return call.Company_Name || 'Unknown Company';
  },
    toggleAudio(call) {
      if (this.playingCallId === call.id) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio = null;
        this.playingCallId = null;
      } else {
        if (this.audio) {
          this.audio.pause();
          this.audio.currentTime = 0;
        }
        let url = call.recordingUrl;
        if (url) {
          this.audio = new Audio(url);
          this.audio.play();
          this.playingCallId = call.id;
          this.audio.onended = () => {
            this.audio = null;
            this.playingCallId = null;
          };
        } else {  
          alert("No audio URL available");
        }
      }
    },
    downloadAudio(call) {
      let url = call.recordingUrl;
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'call_audio.wav';
        link.click();
      } else {
        alert("No audio URL available");
      }
    }
  },
  mounted() {
    this.salesRepId = this.$route.query.salesRepId;
    this.toFilter = this.$route.query.toCallsFilter;
    this.fetchData();
  }
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}
.container {
  margin: 20px auto;

  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.title {
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-box,
.filter-box,
.date-filter-box {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.search-box input,
.filter-box select,
.date-filter-box select,
.date-range-picker input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.search-box input {
  flex: 1;
}
.date-range-picker {
  display: none;
  align-items: center;
}
.date-range-picker input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.date-range-picker button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}
.date-range-picker button:hover {
  background-color: #45a049;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 12px 8px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  white-space: nowrap;
}
th {
  background: #f2f2f2;
  cursor: pointer;
}
th:hover {
  background: #e0e0e0;
}
.play-button,
.download-button,
.tag-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  border-radius: 10px;
  cursor: pointer;
}
.play-button:hover,
.download-button:hover,
.tag-button:hover {
  background-color: #45a049;
}
.tag-input {
  padding: 5px;
  font-size: 14px;
  margin-top: 5px;
  width: 100px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.tag-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tag-list li {
  display: inline;
  background-color: #6a11cb;
  color: white;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.tag-list li:hover {
  background-color: #2575fc;
}
.tag-list li .remove-tag {
  margin-left: 5px;
  cursor: pointer;
  color: white;
  background: red;
  border-radius: 50%;
  padding: 0 5px;
  position: absolute;
  top: -5px;
  right: -5px;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.pagination a {
  color: #6a11cb;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 10px;
}
.pagination a.active {
  background-color: #6a11cb;
  color: white;
}
.pagination a:hover:not(.active) {
  background-color: #ddd;
}
.page-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.page-controls select,
.search-box input {
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.totals {
  text-align: right;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .tag-list li {
    font-size: 12px;
    padding: 3px 7px;
  }
  .play-button,
  .download-button,
  .tag-button {
    padding: 3px 7px;
    font-size: 12px;
  }
}
</style>