<template>
    <div class="clock">
      {{ formattedTime }} <span class="timezone">{{ displayTimezone }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      timezone: {
        type: String,
        default: 'America/Los_Angeles', // Default to PST/PDT if no timezone is provided
      },
    },
    data() {
      return {
        time: new Date(),
      };
    },
    computed: {
      formattedTime() {
        return this.time.toLocaleTimeString('en-US', {
          timeZone: this.timezone,
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      },
      displayTimezone() {
        const timezoneNames = {
          'America/New_York': 'EST/EDT',
          'America/Chicago': 'CST/CDT',
          'America/Denver': 'MST/MDT',
          'America/Phoenix': 'MST',
          'America/Los_Angeles': 'PST/PDT',
          'America/Anchorage': 'AKST/AKDT',
          'America/Adak': 'HST/HDT',
          'Pacific/Honolulu': 'HST',
        };
  
        return timezoneNames[this.timezone] || this.timezone;
      },
    },
    created() {
      this.updateTime();
    },
    methods: {
      updateTime() {
        setInterval(() => {
          this.time = new Date();
        }, 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .clock {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff; /* Customize color to fit your header design */
  }
  .timezone {
    margin-left: 8px;
    font-size: 0.875rem; /* Smaller font size for the timezone label */
    font-weight: normal;
    color: #ccc; /* Lighter color for the timezone label */
  }
  </style>
  